function article_1() {
	return {
		date: "2023 June",
		title: "Avoid using Doctrine's Collection filter",
		description:
			"These powerful data structures are a staple of object-oriented PHP development, offering a convenient way to manage complex data relationships. However, as any seasoned developer can tell you, working with Doctrine Collections can also be a double-edged sword.",
		keywords: [
			"Doctrine Collection",
			"Doctrine Collection filtering",
		],
		link: "https://dev.to/ssmellow/doctrines-collection-filter-method-a-double-edged-sword-he",
	};
}

function article_2() {
	return {
		date: "2025 January",
		title: "Creating Self-Testable APIs for Seamless Integration",
		description:
			"We'll dive into how we built a self-testable API mechanism. The goal was simple -  to build a system which will validate the input and output itself and notify the developers if there are any inconsistencies",
		keywords: [
			"Self-Testable API",
			"Legacy application",
		],
		link: "https://dev.to/ssmellow/from-legacy-to-modern-creating-self-testable-apis-for-seamless-integration-c8p",
	};
}

const myArticles = [article_1, article_2];

export default myArticles;
